import appFeatureFlags from "@/domains/App/featureFlags";
import billPayFeatureFlags from "@/domains/BillPay/featureFlags";
import cardFeatureFlags from "@/domains/Card/featureFlags";
import cashFeatureFlags from "@/domains/Cash/featureFlags";
import integrationsFeatureFlags from "@/domains/Integrations/featureFlags";
import kycFeatureFlags from "@/domains/Kyc/featureFlags";
import limitExperienceFeatureFlags from "@/domains/LimitExperience/featureFlags";
import onboardingFeatureFlags from "@/domains/Onboarding/featureFlags";
import { primitivesFeatureFlags } from "@/domains/Primitives";
import productivityFeatureFlags from "@/domains/Productivity/featureFlags";
import referralsFeatureFlags from "@/domains/Referrals/featureFlags";
import spendFeatureFlags from "@/domains/Spend/featureFlags";
import supportExperiencesFeatureFlags from "@/domains/Support/featureFlags";
import travelFeatureFlags from "@/domains/Travel/featureFlags";
import trustFeatureFlags from "@/domains/Trust/featureFlags";
import underwritingFeatureFlags from "@/domains/Underwriting/featureFlags";
import vendorFeatureFlags from "@/domains/Vendor/featureFlags";
import apolloFeatureFlags from "@/features/Apollo/featureFlags";
import authenticationFeatureFlags from "@/features/Authentication/helpers/featureFlags";
import customBrandingFeatureFlags from "@/features/CustomThemeBranding/featureFlags";
import fraudAlertsFeatureFlags from "@/features/FraudAlerts/featureFlags";
import type { FeatureFlagValue } from "@/features/LaunchDarkly/sharedTypes";
import accountManagementFlags from "@/routes/AccountManagement/featureFlags";
import onboardingRouteFlags from "@/routes/Onboarding/featureFlags";
import rewardsFeatureFlags from "@/routes/Rewards/featureFlags";
import walletFlags from "@/routes/Wallet/featureFlags";

type AssertPrimitive<T> = T extends number
  ? number
  : T extends string
    ? string
    : T extends boolean
      ? boolean
      : T;

// recursively remove `readonly` modifier of object type
type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

/**
 * Convert the feature flag tuple array type to a map type and assert the
 * primitive type of the feature flag value based on the specified default.
 *
 * T = array of feature flag tuples
 * T[number] = the feature flag tuple i.e. ["my-feature-flag", false]
 * T[number][0] = the feature flag string
 *
 * Extract<T[number], [K, FeatureFlagValue]>[1] returns the default value of the
 * tuple, which we then assert to a primitive type based on its value.
 */
type FeatureFlagTupleToMap<T extends [string, FeatureFlagValue][]> = {
  [K in T[number][0]]: AssertPrimitive<
    Extract<T[number], [K, FeatureFlagValue]>[1]
  >;
};

export type FeatureFlagsSet = FeatureFlagTupleToMap<
  DeepWriteable<typeof allFeatureFlags>
>;
// ! Please do not add new domain-specific feature flags to this file

/**
 * @deprecated
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export const allFeatureFlags = [
  ...accountManagementFlags,
  ...apolloFeatureFlags,
  ...appFeatureFlags,
  ...authenticationFeatureFlags,
  ...billPayFeatureFlags,
  ...vendorFeatureFlags,
  ...cardFeatureFlags,
  ...cashFeatureFlags,
  ...customBrandingFeatureFlags,
  ...fraudAlertsFeatureFlags,
  ...integrationsFeatureFlags,
  ...kycFeatureFlags,
  ...limitExperienceFeatureFlags,
  ...onboardingFeatureFlags,
  ...primitivesFeatureFlags,
  ...productivityFeatureFlags,
  ...referralsFeatureFlags,
  ...rewardsFeatureFlags,
  ...spendFeatureFlags,
  ...supportExperiencesFeatureFlags,
  ...travelFeatureFlags,
  ...trustFeatureFlags,
  ...underwritingFeatureFlags,
  ...walletFlags,
  ...onboardingRouteFlags,
  // ! Please do not add new domain-specific feature flags to this file
  ["mfa-disable-phone-update", true],
  ["mfa-required", true],
  ["test_domain.zzzz-test-1-do-2-not-3-remove", false],
  ["zzzz-test--again--do-not-remove", false],
  ["mobile-upsells-reset-state", false],
  ["plaid-personal-bank-account", false],
  // ! Please do not add new domain-specific feature flags to this file
] as const;

export type FeatureFlag = keyof FeatureFlagsSet;
