export const cleanupLdCache = (userIdToKeep: string) => {
  const ldCacheKeyRegex = /^ld:([^:]+):(.+)$/;

  Array.from(
    // I didn't use Object.keys(localStorage) because it wasn't supported by the localStorage mock for jest
    {
      length: window.localStorage.length,
    },
    (_, index) => window.localStorage.key(index),
  )
    .filter((key): key is string => {
      const matches = key?.match(ldCacheKeyRegex);
      if (key && matches) {
        try {
          const decoded = JSON.parse(window.atob(matches[2]));
          return decoded.key !== userIdToKeep;
        } catch {}
      }
      return false;
    })
    .forEach((keyToRemove) => {
      // We need to filter first and do the removal at the end because not doing so messes with the order of keys in localStorage
      // and we won't iterate over all localStorage items properly
      window.localStorage.removeItem(keyToRemove);
    });
};
