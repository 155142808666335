import type { Getter } from "jotai";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { userIdAtom } from "@/features/Authentication/atoms/userPropertiesQueryAtoms";
import type { FeatureFlagValue } from "@/features/LaunchDarkly/sharedTypes";

interface LaunchDarklyDevOverrides {
  [k: string]: {
    [k: string]: FeatureFlagValue;
  };
}

type LaunchDarklyAtomAction =
  | {
      type: "add";
      flagName: string;
      value: FeatureFlagValue;
    }
  | {
      type: "remove";
      flagName: string;
    }
  | {
      type: "removeAll";
    };

export const launchDarklyAllOverridesAtom =
  atomWithStorage<LaunchDarklyDevOverrides>("FEATURE_FLAG_DEV_OVERRIDES", {});

export const launchDarklyDevUserIdAtom = atom<string | undefined>(undefined);

const getNamespaceKey = (get: Getter) => {
  const devUserId = get(launchDarklyDevUserIdAtom);
  const userId = get(userIdAtom);
  return devUserId || userId || "";
};

const namespacedOverridesAtom = atom((get) => {
  const allOverrides = get(launchDarklyAllOverridesAtom);
  const namespaceKey = getNamespaceKey(get);
  return {
    ...((allOverrides || {})[namespaceKey] || {}),
  };
});

export const launchDarklyDevOverridesAtom = atom<
  LaunchDarklyDevOverrides[string],
  [LaunchDarklyAtomAction],
  void
>(
  (get) => get(namespacedOverridesAtom),
  (get, set, action) => {
    const allOverrides = get(launchDarklyAllOverridesAtom);
    const namespaceKey = getNamespaceKey(get);
    const namespacedOverrides = get(namespacedOverridesAtom);

    if (action.type === "add") {
      const updatedOverrides = {
        ...allOverrides,
        [namespaceKey]: {
          ...namespacedOverrides,
          [action.flagName]: action.value,
        },
      };

      set(launchDarklyAllOverridesAtom, updatedOverrides);
    }

    if (action.type === "remove") {
      const { [action.flagName]: _, ...rest } = namespacedOverrides;
      const updatedOverrides = {
        ...allOverrides,
        [namespaceKey]: rest,
      };

      set(launchDarklyAllOverridesAtom, updatedOverrides);
    }

    if (action.type === "removeAll") {
      const { [namespaceKey]: _, ...rest } = allOverrides;

      set(launchDarklyAllOverridesAtom, rest);
    }
  },
);
