import { useAtom } from "jotai";
import * as React from "react";
import {
  launchDarklyDevUserIdAtom,
  launchDarklyDevOverridesAtom,
} from "@/features/LaunchDarkly/atoms/launchDarklyOverridesAtoms";
import type { FeatureFlagValue } from "@/features/LaunchDarkly/sharedTypes";

/*
 ************************************************************************************************
 *
 *  NOTE: This hook is only meant to support the non-production Dev Panel experience
 *  (@/features/DevPanel). Overrides should not be created anywhere other than from that feature.
 *
 ************************************************************************************************
 */

export const useFeatureFlagDevOverrides = (
  defaultUserId?: string,
): {
  overrides?: {
    [key: string]: FeatureFlagValue;
  };
  removeOverride?: (flagName: string) => void;
  addOverride?: (flagName: string, value: FeatureFlagValue) => void;
  removeAllOverrides?: () => void;
} => {
  const env = process.env.APP_ENV;

  const [userId, setUserId] = useAtom(launchDarklyDevUserIdAtom);
  const [overrides, dispatch] = useAtom(launchDarklyDevOverridesAtom);

  const removeOverride = React.useCallback(
    (flagName: string) => {
      dispatch({
        type: "remove",
        flagName,
      });
    },
    [dispatch],
  );

  const removeAllOverrides = React.useCallback(() => {
    dispatch({
      type: "removeAll",
    });
  }, [dispatch]);

  const addOverride = React.useCallback(
    (flagName: string, value: FeatureFlagValue) => {
      dispatch({
        type: "add",
        flagName,
        value,
      });
    },
    [dispatch],
  );

  React.useEffect(() => {
    setUserId(defaultUserId);
  }, [setUserId, defaultUserId]);

  // Overrides should never return for prod environments, nor should
  // the ability to manipulate overrides. Also, since overrides are
  // namespaced in localstorage by userId, return no overrides if
  // userId doesn't exist for some reason.
  if (!userId || !env || env === "prod") {
    return {};
  }

  return {
    overrides,
    removeOverride,
    addOverride,
    removeAllOverrides,
  };
};
