import type { LDUser } from "launchdarkly-js-client-sdk";
import type {
  UserProperties,
  LDUserProperties,
} from "@/features/LaunchDarkly/sharedTypes";

/**
 * Shared ID/key for anonymous users.
 */
export const LAUNCH_DARKLY_ANONYMOUS_USER_KEY = "anonymous-user";

/**
 * LaunchDarkly requires us to pass in all properties that are not specified
 * in their built-in properties list as "custom" properties in a separate key
 * in the user object
 *
 * @see https://docs.launchdarkly.com/sdk/features/user-config#javascript
 */
const isBuiltinLdUserProperty = (userPropertyKey: string) =>
  [
    "ip",
    "firstName",
    "lastName",
    "country",
    "email",
    "avatar",
    "name",
    "anonymous",
  ].includes(userPropertyKey);

type LimitedUserProperties<T extends UserProperties> = keyof Omit<
  T,
  keyof LDUserProperties
> extends never
  ? T & {
      custom: {};
    }
  : Omit<T, keyof Omit<T, keyof LDUserProperties>> & {
      custom: Omit<T, keyof LDUserProperties>;
    };

/**
 * Split a single map of user properties into a map of custom properties
 * nested inside a map of built-in properties for LaunchDarkly to consume
 */
export const toLDUserProperties = <T extends UserProperties>(props: T) => {
  return Object.keys(props).reduce(
    (ldUserProperties, userPropertyKey) =>
      isBuiltinLdUserProperty(userPropertyKey)
        ? {
            ...ldUserProperties,
            [userPropertyKey]: props[userPropertyKey],
          }
        : {
            ...ldUserProperties,
            custom: {
              ...ldUserProperties.custom,
              [userPropertyKey]: props[userPropertyKey],
            },
          },
    {
      custom: {},
    } as LimitedUserProperties<T>,
  );
};

/**
 * Transform a user ID and combined set of LaunchDarkly and custom user
 * properties into a unified LaunchDarkly user object.
 *
 * This function employs an optimiation to reduce the number of billable
 * anonymous users.
 */
export const toLDUser = <T extends UserProperties>(
  userId: string,
  props: T,
): LDUser => {
  const user = {
    key: userId,
    ...toLDUserProperties(props),
  };

  // As an optimization to reduce billable Client MAUs, we use a single, shared
  // anonymous user key, and include the unique anonymous ID as a custom user
  // property, to make it possible to perform percentage rollouts for anonymous
  // users based upon the `anonymousId` user property.
  if (props.anonymous) {
    return {
      ...user,
      key: LAUNCH_DARKLY_ANONYMOUS_USER_KEY,
      anonymous: false,
      custom: {
        ...user.custom,
        anonymousId: userId,
      },
    };
  }

  return user;
};
