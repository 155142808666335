import { atomWithReducer } from "jotai/utils";
import type { LDClient, LDFlagSet } from "launchdarkly-js-client-sdk";

interface LaunchDarklyAtomState {
  client: LDClient | undefined;
  // this ensure an update to feature flag values when a user changes
  userId: string | undefined;
  flagsCache: LDFlagSet;
}

type LaunchDarklyAtomAction =
  | {
      type: "initialize";
      client: LDClient;
    }
  | {
      type: "identify";
      userId: string;
    }
  | {
      type: "override";
      client: LDClient;
      userId?: string;
    };

export const launchDarklyAtom = atomWithReducer<
  LaunchDarklyAtomState,
  LaunchDarklyAtomAction
>(
  {
    client: undefined,
    userId: undefined,
    flagsCache: {},
  },
  (prev, action) => {
    if (action?.type === "initialize") {
      return { ...prev, client: action.client };
    }

    if (action?.type === "identify") {
      // reset flags cache on identify to ensure flags are refetched
      return { ...prev, userId: action.userId, flagsCache: {} };
    }

    if (action?.type === "override") {
      return {
        client: action.client,
        userId: action.userId,
        // reset flags cache on override; useful to support LD flags mocks
        flagsCache: {},
      };
    }

    return prev;
  },
);
