import type { FeatureFlagValue } from "@/features/LaunchDarkly/sharedTypes";

export enum LaunchDarklyAnalyticsEvents {
  FeatureFlagValueCached = "ld.feature.flag.value.cached",
}

type FeatureFlagValuePayload = {
  flagName: string;
  flagValue: FeatureFlagValue;
};

export type LaunchDarklyAnalyticsEventsSchemaMap = {
  [LaunchDarklyAnalyticsEvents.FeatureFlagValueCached]: FeatureFlagValuePayload;
};
