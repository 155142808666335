import { useAtom } from "jotai";
import { initialize as initializeLD } from "launchdarkly-js-client-sdk";
import type { LDClient, LDFlagSet } from "launchdarkly-js-client-sdk";
import * as React from "react";
import { launchDarklyAtom } from "@/features/LaunchDarkly/atoms/launchDarklyAtoms";
import { cleanupLdCache } from "@/features/LaunchDarkly/helpers/cleanupLdCache";
import { toLDUser } from "@/features/LaunchDarkly/helpers/toLDUser";
import type { UserProperties } from "@/features/LaunchDarkly/sharedTypes";
import { getEnvironment } from "@/helpers";
import { sleep } from "@/helpers/async";
import { internalTrackError } from "@/helpers/errorTracking";

/**
 * Timeout (in milliseconds) for auto resolving the LaunchDarkly identify call
 */
const LAUNCH_DARKLY_TIMEOUT = 1000;

/** Error thrown when LaunchDarkly times out */
class LaunchDarklyTimeoutError extends Error {}

/**
 * Initialize the LaunchDarkly client
 */
const init = async (anonymousId: string): Promise<LDClient> => {
  // initialize LaunchDarkly client with anonymous user
  const ldClient = initializeLD(
    getEnvironment("LAUNCH_DARKLY_CLIENT_ID") as string,
    toLDUser(anonymousId, { anonymous: true }),
    {
      // bootstrap initial feature flag values using the latest values
      // from localStorage
      bootstrap: "localStorage",
      // disable requests for A/B testing goals as we don't currently use them
      fetchGoals: false,
      // disable streaming updates because we don't want users' experience
      // to change mid-session
      streaming: false,
      // only send analytics events when the `variation` method is called
      sendEventsOnlyForVariation: true,
    },
  );

  // wait for the LaunchDarkly client to become available
  // this will generally happen immediately since we're bootstrapping
  try {
    await Promise.race([
      ldClient.waitForInitialization(),
      sleep(LAUNCH_DARKLY_TIMEOUT).then(() => {
        throw new LaunchDarklyTimeoutError(
          "LaunchDarkly initialization timed out",
        );
      }),
    ]);
  } catch (error) {
    if (error instanceof LaunchDarklyTimeoutError) {
      console.warn(error);
    } else {
      internalTrackError({ error });
    }
  }

  return ldClient;
};

/**
 * Identify a user with LaunchDarkly
 */
const ident = async <T extends UserProperties>(
  client: LDClient,
  userId: string,
  onIdentifyComplete: () => void,
  userProperties?: T,
): Promise<LDFlagSet> => {
  if (userId) {
    cleanupLdCache(userId);
  }

  const identifyLDUser = async () => {
    const value = await client.identify(toLDUser(userId, userProperties ?? {}));
    onIdentifyComplete();
    return value;
  };

  // automatically resolve identify call if the timeout threshold is reached
  // this should rarely occur with most calls resolving in <100ms
  try {
    return await Promise.race([
      identifyLDUser(),
      sleep(LAUNCH_DARKLY_TIMEOUT).then(() => {
        throw new LaunchDarklyTimeoutError("LaunchDarkly identify timed out");
      }),
    ]);
  } catch (error) {
    if (error instanceof LaunchDarklyTimeoutError) {
      console.warn(error.message);
    } else {
      internalTrackError({ error });
    }
    // Return empty flag set on error
    return {};
  }
};

export const useLaunchDarklyInitialization = () => {
  const [{ client, userId: ldUserId, flagsCache }, dispatch] =
    useAtom(launchDarklyAtom);

  /**
   * Shut down the LaunchDarkly client
   */
  const shutdown = React.useCallback(
    async (onDone?: () => {}) => {
      await client?.close(onDone);
    },
    [client],
  );

  const initialize = React.useCallback(
    async (anonymousId: string): Promise<void> => {
      // do not initialize if we already have a client
      if (!client) {
        try {
          const ldClient = await init(anonymousId);
          dispatch({
            type: "initialize",
            client: ldClient,
          });
        } catch (error) {
          // log the error to the console
          console.error(error);
        }
      }
    },
    [client, dispatch],
  );

  const identify = React.useCallback(
    async <T extends UserProperties>(
      userId: string,
      userProperties?: T,
    ): Promise<LDFlagSet> => {
      // ensure we have a client
      if (client) {
        const featureFlags = await ident(
          client,
          userId,
          () =>
            dispatch({
              type: "identify",
              userId,
            }),
          userProperties,
        );

        return featureFlags;
      }
      throw new Error(
        "Could not identify with LaunchDarkly: client not initialized",
      );
    },
    [client, dispatch],
  );

  return {
    shutdown,
    initialize,
    identify,
    client,
    userId: ldUserId,
    flagsCache,
  };
};
